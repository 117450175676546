import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Div from '../Div'
import './post.scss'
import moment from 'moment';

export default function Post({url, src, img, alt, date, title, shortDesc, Desc, category, quote, categoryHref, href}) {
  
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault()
    navigate('/blog/blog-details', { state: { img:img, title:title, shortDesc:shortDesc, Desc:Desc, date:date, category:category, quote:quote, categoryHref:categoryHref, href:href } });
  }

  return (
    <Div className="cs-post cs-style1">
      <Link to={url} onClick={(e) => handleNavigate(e)} className="cs-post_thumb">
        <img src={process.env.REACT_APP_BASE_URL + src} alt={alt} />
        <Div className="cs-post_overlay" />
      </Link>
      <Div className="cs-post_info">
        <Div className="cs-posted_by">{moment(date).format('DD-MMM-YYYY')}</Div>
        <h2 className="cs-post_title"><Link onClick={(e) => handleNavigate(e)}>{title}</Link></h2>
      </Div>
    </Div>
  )
}
