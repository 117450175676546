import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './portfolio.scss';
import '../../scss/common/_image-modal.scss'
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper'


export default function GalleryPortfolio({ href, src, title, subtitle, variant, serviceIndex, service, item }) {
  const [toggle, setToggle] = useState(false);
  const navigate = useNavigate();
  const handleOpen = (e) => {
    setToggle(true);
  };

  const handleClose = () => {
    setToggle(false);
  }; 

  return (<>
    <div onClick={(e) => handleOpen(e)} className={`cs-portfolio cs-bg ${variant ? variant : 'cs-style1'}`}>
      <>
        <Div className="cs-portfolio_hover" />
        <Div
          className="cs-portfolio_bg cs-bg"
          style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL + src})` }}
        />
        <Div className="cs-portfolio_info">
          <Div className="cs-portfolio_info_bg cs-accent_bg" />
          <h2 className="cs-portfolio_title">{title}</h2>
          <Div className="cs-portfolio_subtitle">{subtitle}</Div>
        </Div>
      </>
    </div>

    {toggle && (
      <Div className="cs-image_popup active">
        <Div className="cs-image_popup_overlay" onClick={handleClose} />
        <Div className="cs-image_popup_content">
          <Div className="cs-image_popup_layer" />
          <Div className="cs-image_popup_container">
            <Div className="cs-image_popup_align">
              {item && item.additionalimages && item.additionalimages.length > 0 ? (
                <Swiper
                  navigation={true}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper"
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                >
                  {item.additionalimages.map((src, i) => (
                    <SwiperSlide key={i}><img src={process.env.REACT_APP_BASE_URL + src} alt="Details" className="w-100" /></SwiperSlide>
                  ))}
                </Swiper>
              ) : (
                <h2 className='gallery_nodata' >No Gallery found</h2>
              )}
            </Div>
            <Div className="cs-image_popup_close" onClick={handleClose} />
          </Div>
        </Div>
      </Div>
    )}
  </>
  );
}