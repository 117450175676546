// // ToggleColorMode.js
// import React, { useContext, useEffect, useState } from 'react';
// import IconButton from '@mui/material/IconButton';
// import Button from '../components/Button';
// import { Icon } from '@iconify/react';

// const ToggleColorMode = () => {
//   const [mode, setMode] = useState(() => {
//     const storedMode = localStorage.getItem('mode');
//     return storedMode ? storedMode : 'dark';
//   });

//   useEffect(() => {
//     localStorage.setItem('mode', mode);
//     document.body.className = mode === 'light' ? 'light-mode' : 'dark-mode';
//   }, [mode]);

//   const toggleMode = () => {
//     setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
//   };

//   return (
//     <IconButton onClick={toggleMode} color="inherit">
//     {/* // <IconButton color="inherit"> */}
//       {mode === 'light' ? <Icon icon="fluent:weather-moon-16-filled" /> : <Icon icon="bx:sun" />}
//     </IconButton>

//   );
// };

// export default ToggleColorMode;




// ToggleColorMode.js
import React, { useContext, useEffect, useState } from 'react';
import IconButton from '@mui/material/IconButton';
import Button from '../components/Button';
import { Icon } from '@iconify/react';
import { Coloruser } from "../redux/action/index"
import { useDispatch } from "react-redux"
const ToggleColorMode = () => {
  let dispatch = useDispatch()
  const [mode, setMode] = useState(() => {
    const storedMode = localStorage.getItem('mode');
    return storedMode ? storedMode : 'dark';
  });

  useEffect(() => {
    localStorage.setItem('mode', mode);
    dispatch(Coloruser(mode))
    document.body.className = mode === 'light' ? 'light-mode' : 'dark-mode';
  }, [mode]);

  const toggleMode = () => {
    setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
  };

  return (
    <IconButton onClick={toggleMode} color="inherit">
      {mode === 'light' ? <Icon icon="fluent:weather-moon-16-filled" /> : <Icon icon="bx:sun" />}
    </IconButton>
  );
};

export default ToggleColorMode;


