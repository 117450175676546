import { createStore, applyMiddleware, compose } from 'redux';
import {thunk} from 'redux-thunk';
import { rootReducer } from "./reducer/reducers";

const middleware = [thunk];

const store = createStore(
  rootReducer,
  applyMiddleware(...middleware)
);

export default store;

