import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Div from '../Div'
import moment from 'moment';

export default function RecentPost({title, data}) {
  const navigate = useNavigate();
  const handleNavigate = (e, item) => {
    e.preventDefault()
    navigate('/blog/blog-details', { state: { img:item.Images, title:item.Blog_Name, shortDesc:item.shortdescription, Desc:item.Description, date:item.createdAt, category:item.Category && item.Category.category_name, quote:item.blogqoute, categoryHref:'/blog', href:'/blog/blog-details' } });
  }

  return (
    <>
      <h4 className="cs-sidebar_widget_title">{title}</h4>
      <ul className="cs-recent_posts">
        {data?.map((item, index)=> (
          <li key={index}>
            <Div className="cs-recent_post">
              <Link onClick={(e) => handleNavigate(e, item)} className="cs-recent_post_thumb">
                <Div className="cs-recent_post_thumb_in cs-bg" style={{backgroundImage: `url(${process.env.REACT_APP_BASE_URL + item.Images[0]})`}} />
              </Link>
              <Div className="cs-recent_post_info">
                <h3 className="cs-recent_post_title"><Link onClick={(e) => handleNavigate(e, item)} >{item.Blog_Name}</Link></h3>
                {/* <h3 className="cs-recent_post_title">{item.Blog_Name}</h3> */}
                <Div className="cs-recent_post_date cs-primary_40_color">{moment(item.createdAt).format('DD-MMM-YYYY')}</Div>
              </Div>
            </Div>
          </li>
        ))}
      </ul>
    </>
  )
}
