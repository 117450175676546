import axios from "axios";
import { COLOR, COLOR_SUCCESS, COLOR_FAILURE, } from "../action.types/index"

export function Coloruser(data) {
    return (dispatch) => {
        dispatch({
            type: COLOR
        })
        dispatch({
            type: COLOR_SUCCESS,
            payload: data,
        })
    }
}
