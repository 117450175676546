import React, { useEffect, useState } from 'react'
import Div from '../Div'
import AuthorWidget from '../Widget/AuthorWidget'
import RecentPost from '../Widget/RecentPost'
import SearchWidget from '../Widget/SearchWidget'
import SideMenuWidget from '../Widget/SideMenuWidget'
import TagWidget from '../Widget/TagWidget'
import axios from 'axios'
import { Icon } from '@iconify/react'
import moment from 'moment'

export default function Sidebar() {
  useEffect(() => {
    getBlogsCat()
  }, [])
  const [topBlogscat, setTopBlogscat] = useState([])
  const [recentBlog, setRecentBlog] = useState([])

  const getBlogsCat = (e) => {
    axios.get(process.env.REACT_APP_BASE_URL + `Blog/topCategories`).then((res) => {  
      setTopBlogscat(res.data.topCategories)
    }).catch((error) => {
      console.log(error, "error ");
    });
  }

  useEffect(() => {
    getRecentBlogs()
  }, [])

  const getRecentBlogs = (e) => {
    axios.get(process.env.REACT_APP_BASE_URL + `Blog/recentBlogs`).then((res) => {
      setRecentBlog(res.data.recentBlogs)
    }).catch((error) => {
      console.log(error, "error");
    });
  }
  
  return (
    <>
      {/* <Div className="cs-sidebar_item">
        <AuthorWidget 
          src='/images/avatar_1.png'
          name='Kuber Jontra' 
          description='At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum.'
        />
      </Div> */}
      {/* <Div className="cs-sidebar_item">
        <SearchWidget title='Search'/>
      </Div> */}
      <Div className="cs-sidebar_item">

        <h4 className="cs-sidebar_widget_title">Categories</h4>
        <ul className='cs-side_menu_widget'>
          {topBlogscat && topBlogscat?.map((item, index) => (
            <li key={index}>
              <Icon icon="material-symbols:keyboard-double-arrow-right-rounded" />
              <a key={index}>{item.category_name}</a>
            </li>
          ))}
        </ul>
      </Div>
      {/* <Div className="cs-sidebar_item">        
        <h4 className="cs-sidebar_widget_title">Archives</h4>
        <ul className="cs-recent_posts">
          {recentPostData && recentPostData?.map((item, index) => (           
            <li key={index}>
              <Div className="cs-recent_post">              
                <Div className="cs-recent_post_thumb_in cs-bg" style={{backgroundImage: `url(${item.thumb})`}} />               
                <Div className="cs-recent_post_info">                 
                  <h3 className="cs-recent_post_title">{item.title}</h3>
                  <Div className="cs-recent_post_date cs-primary_40_color">{moment(item.date).format('DD-MMM-YYYY')}</Div>
                </Div>
              </Div>
            </li>
          ))}
        </ul>
      </Div> */}
      <Div className="cs-sidebar_item">
        <RecentPost title='Archives' data={recentBlog}/>
      </Div>


      {/* <Div className="cs-sidebar_item">
        <SideMenuWidget title='Archives' data={archiveData}/>
      </Div> */}
      {/* <Div className="cs-sidebar_item">
        <TagWidget title='Tags' data={tagData} />
      </Div> */}
    </>
  )
}
