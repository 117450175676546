import React from 'react'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react';
import Div from '../Div';

export default function SocialWidget({ contactdetails }) {

  return (
    <Div className="cs-social_btns cs-style1">
      { contactdetails && contactdetails.linkedin&&<a href={contactdetails && contactdetails.linkedin} target="_blank" className="cs-center"><Icon icon="fa6-brands:linkedin-in" /></a>}
      {contactdetails && contactdetails.facebook&&<a href={contactdetails && contactdetails.facebook} target="_blank" className="cs-center"><Icon icon="fa:facebook" /></a>}
      {contactdetails && contactdetails.instagram&&<a href={contactdetails && contactdetails.instagram} target="_blank" className="cs-center"><Icon icon="grommet-icons:instagram" /></a>}
        {contactdetails && contactdetails.twitter&&<a href={contactdetails && contactdetails.twitter} target="_blank" className="cs-center"><Icon icon="ri:twitter-x-line" /></a>}
     
    </Div>
  )
}

