import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import parse from 'html-react-parser';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify";
import { TextareaAutosize } from "@mui/material";

export default function LeadforgeContact() {
	const [contactdetails, setContactDetails] = React.useState([]);
	const [phonenumber, setPhonenumber] = React.useState();
	const [buttonview, setButtonview] = React.useState(false);
	const colordata = useSelector((state) => state && state.colordata)
	const [contactdetl, setContactdetl] = React.useState({ name: "", email: "", company: "",industry:'', phonenumber: "",message:''});
	const [mapview, setMapview] = React.useState('');
	const [errror, setError] = React.useState({ name: "", email: "", company: "",phonenumber: "",message:'' });
	const [serviceavlb, setServiceavlb] = React.useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	});
	pageTitle("Contact Us");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		getContactDetails();
	}, []);

	const getContactDetails = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `admincontact/getAdmindetailsPublic`).then((res) => {
			setContactDetails(res.data.admindetails.contactdetails);
			setMapview(res.data.admindetails.contactdetails && res.data.admindetails.contactdetails?.length > 0 && res.data.admindetails.contactdetails[0].googlemap)
		}).catch((error) => {
			console.log(error, "error");
		});
	};


	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value });
		setError({ ...errror, [e.target.name]: "" });
	};
	const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked });
	};

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.name && !contactdetl.email && !contactdetl.phonenumber && !contactdetl.message) {
			setError({ ...errror, name: 'This field is required', email: 'This field is required', phonenumber: 'This field is required',message:'This field is required' })
			return false
		}
		if (!contactdetl.name) {
			setError({ ...errror, name: "This field is required" });
			return false;
		}
		if (!contactdetl.email) {
			setError({ ...errror, email: "This field is required" });
			return false;
		}
		if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: "Please enter valid email" });
			return false;
		}
		if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: "This field is required" });
			return false;
		}
        if (!contactdetl.message) {
			setError({ ...errror, message: "This field is required" });
			return false;
		}
		return true;
	};

	const handleClik = (data, index) => {
		const updatedList = contactdetails.map((item, i) => ({
			...item,
			selected: i === index,
		}));
		setContactDetails(updatedList);
		setMapview(data.googlemap);
	};

	const handleSubmit = () => {
		// event.preventDefault();
		var valid = validate();
		if (valid == true) {
			setButtonview(true)
			let body = {
				"name":contactdetl.name,
				"email":contactdetl.email,
				"phone":contactdetl.phonenumber,
				"company":contactdetl.company,
				"industry":contactdetl.industry,
				"message":contactdetl.message,
			  }
			axios.post(process.env.REACT_APP_BASE_URL + "ServiceContact/ContactUs", body)
			.then((res) => {
				setButtonview(false)
				setContactdetl({ name: "", lastname: "", email: "", company: "", phonenumber: "", });
				
				toast.success('Success')
				// window.open("https://api.theimpression.me/public/Theimpession-companyprofile.pdf");
			}).catch((err) => {
				setButtonview(false)
				console.log(err);
			});
		}
	};
	function displayAddress() {
		var element = document.getElementById("showhover");
		element.classList.add("cs-style1 ");
	}
	function hideAddress() {
		var element = document.getElementById("showhover");
		element.classList.remove("cs-style1");
	}
	return (
		<>
			
			<Spacing lg="80" md="40" />

			<Div className="container contact_page cs-shape_wrap_4">
				<Div className="cs-shape_4"></Div>
				<Div className="cs-shape_4"></Div>
				<Div className="row">
					<Div className="col-lg-6">
						<SectionHeading
							title="Contact Us"
							subtitle="Get in touch with us"
						/>
						<Spacing lg="55" md="10" />

						<Spacing lg="55" md="10" />


					</Div>
					<Div className="col-lg-6">
						<form className="row" >
							<Div className="col-sm-6">
								<label className="cs-primary_color">Name*</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.name}
									name="name"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.name}</p>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Email*</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.email}
									name="email"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.email}</p>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Company</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.company}
									name="company"
									onChange={handleContactdetail}
								/>
								<Spacing lg="20" md="20" />
							</Div>
                            <Div className="col-sm-6">
								<label className="cs-primary_color">Industry</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.firstname}
									name="industry"
									onChange={handleContactdetail}
								/>
		
								<Spacing lg="20" md="20" />
							</Div>
							

							<Div className="col-sm-6">
								<label className="cs-primary_color">Phone Number*</label>
								<PhoneInput
									value={contactdetl.phonenumber}
									className="cs-form_field"
									defaultCountry="LB"
									onChange={(e) => {
										setContactdetl({ ...contactdetl, phonenumber: e });
										setError({ ...errror, phonenumber: "" });
									}}
								/>
								<p style={{ color: "red" }}> {errror.phonenumber}</p>
								<Spacing lg="20" md="20" />
							</Div>
                            
                            <Div className="col-sm-12">
								<label className="cs-primary_color">Message*</label>
								<TextareaAutosize
                                                aria-label="empty textarea"
                                                placeholder="Enter Your Message"
                                                className='textfield textarea cs-form_field' 
                                                value={contactdetl.message}
									            name="message"
									            onChange={handleContactdetail}
                                                minRows={3}
                                                style={{ resize: 'none' }} />
                                <p style={{ color: "red" }}> {errror.message}</p>
								<Spacing lg="20" md="20" />
							</Div>




							
							
							
							
							
						</form>
                        <Spacing lg="20" md="20" />
						<Div className="col-sm-12">
							{buttonview == false ?
								<button className="cs-btn cs-style1" onClick={() => handleSubmit()}>
									<span>Submit</span>
									<Icon icon="bi:arrow-right" />
								</button> :
								<button className="cs-btn cs-style1">
									<span>Please wait...</span>
								</button>}
						</Div>
					</Div>

				</Div>
            </Div>
			<Spacing lg="50" md="00" />

			


			
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</>
	);
}