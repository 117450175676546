import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function PageHeadingSerice({ title, bgSrc, pageLinkText }) {
  
  return (
    <Div
      className="cs-page_heading cs-style1 cs-center text-center cs-bg banner_height"
      style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL + bgSrc})` }}
    >
    </Div>
  );
}

