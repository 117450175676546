import {COLOR,COLOR_SUCCESS,COLOR_FAILURE} from "../action.types/index"

const initialState =
{
  colordata: {},
};


export function rootReducer(state = initialState, action) {
    switch (action.type) {
      case COLOR:
        return {
          ...state,
        }
    case COLOR_SUCCESS:
        return {
          ...state,
          colordata: action.payload
        }
      case COLOR_FAILURE:
        return {
          ...state,
        }
    }
}