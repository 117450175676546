import React from 'react';
import { Link } from 'react-router-dom';
import Div from '../Div';
import './portfolio.scss';
import { useNavigate } from 'react-router-dom';

export default function Portfolio({ href, src, title, subtitle, variant, serviceIndex, service, item }) {

  const navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault()
    navigate(href, { state: { index: serviceIndex, data: service } });
  }

  return (
    <a onClick={(e) => handleNavigate(e)} className={`cs-portfolio cs-bg ${variant ? variant : 'cs-style1'}`}>
      <>
        <Div className="cs-portfolio_hover" />
        <Div
          className="cs-portfolio_bg cs-bg"
          style={{ backgroundImage: `url(${process.env.REACT_APP_BASE_URL + src})` }}
        />
        <Div className="cs-portfolio_info">
          <Div className="cs-portfolio_info_bg cs-accent_bg" />
          <h2 className="cs-portfolio_title">{title}</h2>
          <Div className="cs-portfolio_subtitle">{subtitle}</Div>
        </Div>
      </>
    </a>
  );
}
