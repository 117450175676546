import React, { useEffect, useRef } from 'react';
import Div from '../Div';
import './logolist.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import SwiperCore from 'swiper';
import 'swiper/swiper-bundle.min.css';
import axios from 'axios';
import CustomNavigation from '../CustomNavigation';

SwiperCore.use([Navigation]);

export default function TrustedList() {
  const swiperRef = useRef(null);

 

  return (
    <Div className="cs-partner_logo_wrap">
     
        <Swiper
          navigation={false} 
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          modules={[Navigation]}
          className="mySwiper partner_slider"
          slidesPerGroup={5}
          slidesPerView={5}
          loop={false}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 0,
              slidesPerGroup: 2,
            },
            400: {
              slidesPerView: 2,
              spaceBetween: 0,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: 3,
              spaceBetween: 0,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 0,
            },
            1100: {
              slidesPerView: 5,
              spaceBetween: 0,
            },
          }}
        >
     <SwiperSlide className="cs-partner_logo">
              <img src='/images/Meirc.jpg'  alt={'logo'} />
            </SwiperSlide>
         
          {/* <CustomNavigation direction="prev"onClick={() => swiperRef.current.slidePrev()} /> */}
          {/* <CustomNavigation direction="next" onClick={() => swiperRef.current.slideNext()} /> */}
        </Swiper>
     
    </Div>
  );
}
