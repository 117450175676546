import React from 'react';
import Div from '../Div';
import './vertical-links.scss';
import { Icon } from '@iconify/react';

export default function VerticalLinks({ title, data, variant }) {
const filteredData = data.filter(item => item.links !== false);


if(filteredData.length > 0){
  return (
    <Div
      className={`cs-hero_social_wrap cs-primary_font cs-primary_color ${
        variant ? variant : ''
      }`}
    >
      {filteredData && <Div className="cs-hero_social_title">{title}</Div>}
      {filteredData && (
        <ul className="cs-hero_social_links">
          {filteredData.map((item, index) => (
            <li key={index} style={{cursor:'pointer'}}>
              <a href={item.links} target='_blank'>{item.name === 'Instagram' ? <Icon icon="grommet-icons:instagram" /> : item.name === 'LinkedIn' ? <Icon icon="fa6-brands:linkedin-in" /> : item.name === 'Twitter' ? <Icon icon="ri:twitter-x-line" /> : '' }</a>
            </li>
          ))}
        </ul>
      )}
    </Div>
  );
}
else return null

  
}
