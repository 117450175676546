import React from 'react'
import { useState } from 'react'
import Div from '../Div'
import { Link } from 'react-router-dom'
import { Icon } from '@iconify/react'

export default function More() {
    const [selected, setSelected] = useState(0)
    const handelToggle = (index) => {
        if (selected === index) {
            return setSelected(null)
        }
        setSelected(index)
    }
    const accordionData = [
        {
            question: 'What services do you offer?',
            answer: 'We offer a comprehensive range of services including branding, 360 campaigns, content creation, social media management, e-commerce solutions, lead generation, website/application development and much more.'
        },
        {
            question: 'How do I request your services?',
            answer: 'You can easily request our services by contacting us through our website, filling out the inquiry form.'
        },
        {
            question: 'What sets your services apart from others?',
            answer: 'Our services stand out due to our tailored approach, creative expertise, and a commitment to delivering results that align with your unique business goals.'
        },
        {
            question: 'Do you offer any discounts or promotions?',
            answer: 'Periodically, we may offer discounts or promotions. Keep an eye on our website,'
        },
        {
            question: 'Can I customise or tailor the services to my specific needs?',
            answer: 'Absolutely! We prioritise customization to ensure our services align with your unique requirements and objectives.'
        },
        {
            question: 'What is the expected turnaround time for your services?',
            answer: 'Turnaround times vary depending on the service and project scope. We provide estimated timelines during the project proposal phase'
        },
        {
            question: 'What does the branding process involve?',
            answer: 'Our branding process includes a comprehensive analysis of your business, target audience, and market trends. We then develop a unique brand identity, including logos, colours, and messaging, to effectively represent your business.'
        },
        {
            question: 'What is a 360 campaign, and how can it benefit my business?',
            answer: 'A 360 campaign integrates various marketing channels to create a cohesive and immersive brand experience. It can enhance brand visibility, engagement, and overall campaign effectiveness by reaching your audience across multiple touchpoints.'
        },
        {
            question: 'What types of content do you create?',
            answer: 'We create a wide range of content, including written articles, blog posts, visual content, infographics, videos, and more. Our goal is to tailor content to suit your brand and engage your target audience effectively.'
        },
        {
            question: 'Can I provide input or request changes to the content you create?',
            answer: 'Absolutely! We value collaboration and welcome your input. We provide revision opportunities to ensure the content aligns with your vision and goals.'
        },
        {
            question: 'Which social media platforms do you work with?',
            answer: 'We work with a variety of platforms, including but not limited to Facebook, Instagram, and Twitter. Our strategy is tailored to your business goals and target audience.'
        },
        {
            question: 'How do you generate leads for businesses?',
            answer: 'We employ various strategies, such as targeted digital marketing campaigns, content marketing, SEO, and social media initiatives, to attract and convert potential customers into leads.'
        },
        {
            question: 'Do you provide website design and development services?',
            answer: 'Yes, we offer comprehensive website design and development services, including user experience (UX) design, responsive development, and content management system (CMS) integration.'
        },
        {
            question: 'Can you create a mobile application for my business?',
            answer: 'Absolutely, we specialise in mobile application development for both iOS and Android platforms. We work closely with you to understand your requirements and deliver a customised solution.'
        },
        {
            question: 'How do you stay updated on the latest industry trends and innovations?',
            answer: 'We prioritise continuous learning and attend industry conferences, participate in webinars, and engage with relevant online communities to stay abreast of the latest trends.'
        },
        {
            question: "Can you share insights into your team's expertise and qualifications?",
            answer: 'Our team comprises skilled professionals with diverse backgrounds. You can find details about our team members on our website, highlighting their expertise and qualifications.'
        },
        {
            question: 'What role does collaboration play in your service delivery?',
            answer: 'Collaboration is integral to our approach. We believe in working closely with clients, encouraging feedback, and fostering a collaborative environment to ensure project success.'
        },
        {
            question: 'How do you handle unexpected challenges or changes during a project?',
            answer: 'We maintain flexibility in our project management approach to accommodate changes. Our team is experienced in handling unexpected challenges, and we communicate transparently with clients throughout the process.'
        },
        {
            question: "Can you provide a roadmap for the implementation of your services in our business?",
            answer: 'Certainly, we create a detailed roadmap outlining key milestones, timelines, and deliverables to give you a clear understanding of the implementation process.'
        },
        {
            question: 'What steps do you take to ensure data privacy and compliance with regulations?',
            answer: 'We strictly adhere to data privacy regulations and implement robust security measures. Our team is well-versed in compliance requirements, ensuring the confidentiality and integrity of your data.'
        },
        {
            question: 'Can you share any innovative technologies or tools you leverage in your services?',
            answer: 'We embrace innovative technologies to enhance our services. This may include AI-driven analytics, cutting-edge design tools, and the latest development frameworks to stay ahead in the industry.'
        },
    ]




    return (
        <Div className="cs-accordians cs-style1">
            {accordionData.map((item, index) => (
                <Div className={`cs-accordian ${selected === index ? 'active' : ''}`} key={index}>
                    <Div className="cs-accordian_head" onClick={() => handelToggle(index)}>
                        <h2 className="cs-accordian_title">{item.question}</h2>
                        
                        <span className="cs-accordian_toggle cs-accent_color">
                            <svg width={15} height={8} viewBox="0 0 15 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 0L7.5 7.5L15 0H0Z" fill="currentColor" />
                            </svg>
                        </span>
                    </Div>
                    <Div className='cs-accordian_body'>
                        <Div className="cs-accordian_body_in">{item.answer}</Div>
                    </Div>
                </Div>
            ))}
        </Div>
    )
}
