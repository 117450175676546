import axios from "axios";
import React, { createContext, useContext, useEffect, useState } from "react";

const ContactContext = createContext();

function ContactProvider({ children }) {
    const [contactdetails, setContactDetails] = useState([])

    useEffect(function () {
        async function fetchContact() {
            try {
                await axios.get(process.env.REACT_APP_BASE_URL + `admincontact/getAdmindetailsPublic`).then((res) => {                               
                    setContactDetails(res.data.admindetails && res.data.admindetails.contactdetails)
                }).catch((err) => {
                    console.log(err,'error in context')
                })
            } catch {
                console.log('In catchblock')
            }
        }
        fetchContact();
    }, []);

    return (
        <ContactContext.Provider value={{ contactdetails }}>{children}</ContactContext.Provider>
    );
}

const useContact = () => {
    const context = useContext(ContactContext);
    if (context === undefined)
        throw new Error("ContactContext was used outside the ContactProvider");
    return context;
}

export { ContactProvider, useContact };