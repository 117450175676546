import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Div from '../Div'
import './card.scss'

export default function Card({ title, link, src, alt, style, serviceIndex, service }) {
  const navigate = useNavigate();

  const handleNavigate = (e) => {
    e.preventDefault()
    navigate('/service/service-details', { state: { index: serviceIndex, data: service } });
  }


  return (
    <Link onClick={(e) => handleNavigate(e)} className="cs-card cs-style1">
      <>
        <img src={src} alt={alt} className={style} />
        <Div className="cs-card_overlay" />
        <Div className="cs-card_info">
          {/* <span className=" cs-hover_layer3 cs-accent_bg" /> */}
          {/* <h2 className="cs-card_title">{title && title.length > 18 ? title.substring(0,20)+'...' : title}</h2> */}
        </Div>
      </>
    </Link>
  )
}
