import { Icon } from '@iconify/react';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import PageHeading from '../PageHeading';
import Portfolio from '../Portfolio';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import Loader from '../Loader';

export default function ProjectPage() {
    pageTitle('Project');
    const location = useLocation();
    const [itemShow, setItemShow] = useState(7);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);


    useEffect(() => {
        getProjects()
    }, [])

    const [project, setProject] = React.useState([])

    const getProjects = async () => {
        setLoader(true)
        await axios.get(process.env.REACT_APP_BASE_URL + `projects/GetProjectsPublic`).then((res) => {
            setProject(res.data.projects)
            setLoader(false)
        }).catch((error) => {
            console.log(error, "error ");
            setLoader(false)
        });
    };

    if (loader === true) {
        return <Loader />
    }

    return (
        <Div className="Project_main">
            <PageHeading
                title=""
                bgSrc="images/OurWork.jpg"
                pageLinkText="Our Work"
            />
            {/* <Spacing lg="80" md="10" /> */}
            <Div className="container">

                <Spacing lg="60" md="40" />
                {project && project.length > 0 ? <>
                    <Div className="row">
                        {project && project.slice(0, itemShow).map((item, index) => (
                            <Div
                                className={`${index === 3 || index === 6 ? 'col-sm-12 col-md-6 col-lg-4' : 'col-sm-12 col-md-6 col-lg-4'} `}
                                key={index}
                            >
                                <Portfolio
                                    title={item.service_name}
                                    subtitle={'See Details'}
                                    href={'/ourwork/work-details'}
                                    src={item.image}
                                    variant="cs-style1 cs-type1"
                                    serviceIndex={index}
                                    service={project}
                                    item={item}
                                />
                                <Spacing lg="25" md="25" />
                            </Div>
                        ))}
                    </Div>

                    <Div className="text-center">
                        {project && project.length <= itemShow ? (
                            ''
                        ) : (
                            <>
                                <Spacing lg="65" md="40" />
                                <span
                                    className="cs-text_btn"
                                    onClick={() => setItemShow(itemShow + 6)}
                                >
                                    <span>Load More</span>
                                    <Icon icon="bi:arrow-right" />
                                </span>
                            </>
                        )}
                    </Div></> : <>
                    <h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">No projects found</h2>
                    <Spacing lg="90" md="45" />
                </>
                }
            </Div>
            <Spacing lg="80" md="40" />
            <Div className="container">
                <Cta
                    // title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
                    title="Let’s Create <br />something Remarkable!"
					btnText="Download Our Portfolio"
					btnLink="https://api.theimpression.me/public/Theimpession-companyprofile.pdf"
					bgSrc="/images/cta_bg.jpeg"
                />
            </Div>
        </Div>
    );
}
