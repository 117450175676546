import React from 'react';
import { Icon, InlineIcon } from '@iconify/react';


// CSS for custom navigation icons
// const buttonStyle = {
//   position: 'absolute',
//   top: '50%',
//   transform: 'translateY(-50%)',
//   width: '30px',
//   height: '30px',
//   backgroundColor: 'rgba(0, 0, 0, 0.5)',
//   borderRadius: '50%',
//   zIndex: 10,
//   cursor: 'pointer',
//   display: 'flex',
//   alignItems: 'center',
//   justifyContent: 'center',
//   color: 'white',
// };

// Custom navigation component
const CustomNavigation = ({ direction, onClick }) => {
    const buttonClassName = direction === 'prev' ? 'swiper-prev' : 'swiper-next';
    const icon = direction === 'prev' ? 'bxs:left-arrow' : 'bxs:right-arrow';
  return (
    <div style={{  [direction]: '10px' }} onClick={onClick} className={buttonClassName}>
       <Icon icon={icon} />
    </div>
  );
};

export default CustomNavigation;
