import React, { useRef } from "react";
import parse from "html-react-parser";
import "./hero.scss";
import Button from "../Button";
import Div from "../Div";
import VerticalLinks from "../VerticalLinks";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Navigation, Pagination } from 'swiper'
import SwiperCore from 'swiper';
import 'swiper/swiper-bundle.min.css';
import 'swiper/css/pagination';
import CustomNavigation from "../CustomNavigation";
SwiperCore.use([Navigation]);

export default function Hero({
	title,
	subtitle,
	btnText,
	btnLink,
	scrollDownId,
	socialLinksHeading,
	heroSocialLinks,
	bgImageUrl,
}){
	const swiperRef = useRef(null);
	return (
		<Swiper
		navigation={false}
		onSwiper={(swiper) => (swiperRef.current = swiper)}
			modules={[Autoplay, Navigation, Pagination]}
			className="mySwiper blog_slider"
			slidesPerView={1}
			pagination={{
				clickable: true,
			}}
			autoplay={{
				delay: 2000,
				disableOnInteraction: false
			}}
			// autoplay={false}
			allowTouchMove={false}
			loop={true}
		>
			<SwiperSlide className="" >
				<Div
					className="cs-hero cs-bg cs-shape_wrap_1 "
					style={{ backgroundImage: `url(/images/Banner_3.jpg)` }}
				>
					<Div className="cs-shape_1" />
					<Div className="cs-shape_1" />
					<Div className="cs-shape_1" />
					<Div className="container">
						<Div className="cs-hero_text">
							{/* <h1 className="cs-hero_title">{parse(title)}</h1> */}
							<Div className="cs-hero_info">
								<Div>
									{/* <Button btnLink={btnLink} btnText={btnText} /> */}
								</Div>
								<Div>
									{/* <Div className="cs-hero_subtitle">{subtitle}</Div> */}
								</Div>
							</Div>
						</Div>
					</Div>
					{/* <VerticalLinks
						data={heroSocialLinks}
						title={socialLinksHeading}
					/> */}
					<a href={scrollDownId} className="cs-down_btn">
						.
					</a>
				</Div>
			</SwiperSlide>
			<SwiperSlide className="" >
				<Div
					className="cs-hero cs-bg cs-shape_wrap_1"
					style={{ backgroundImage: `url(/images/Banner_1.jpg)` }}
				>
					<Div className="cs-shape_1" />
					<Div className="cs-shape_1" />
					<Div className="cs-shape_1" />
					<Div className="container">
						<Div className="cs-hero_text">
							{/* <h1 className="cs-hero_title">{parse(title)}</h1> */}
							<Div className="cs-hero_info">
								<Div>
									{/* <Button btnLink={btnLink} btnText={btnText} /> */}
								</Div>
								<Div>
									{/* <Div className="cs-hero_subtitle">{subtitle}</Div> */}
								</Div>
							</Div>
						</Div>
					</Div>
					{/* <VerticalLinks
						data={heroSocialLinks}
						title={socialLinksHeading}
					/> */}
					<a href={scrollDownId} className="cs-down_btn">
						.
					</a>
				</Div>
			</SwiperSlide>
			<SwiperSlide className="" >
				<Div
					className="cs-hero cs-bg cs-shape_wrap_1"
					style={{ backgroundImage: `url(/images/Banner_2.jpg)` }}
				>
					<Div className="cs-shape_1" />
					<Div className="cs-shape_1" />
					<Div className="cs-shape_1" />
					<Div className="container">
						<Div className="cs-hero_text">
							{/* <h1 className="cs-hero_title">{parse(title)}</h1> */}
							<Div className="cs-hero_info">
								<Div>
									{/* <Button btnLink={btnLink} btnText={btnText} /> */}
								</Div>
								<Div>
									{/* <Div className="cs-hero_subtitle">{subtitle}</Div> */}
								</Div>
							</Div>
						</Div>
					</Div>
					{/* <VerticalLinks
						data={heroSocialLinks}
						title={socialLinksHeading}
					/> */}
					<a href={scrollDownId} className="cs-down_btn">
						.
					</a>
				</Div>
			</SwiperSlide>
			<CustomNavigation direction="prev"onClick={() => swiperRef.current.slidePrev()} />
          <CustomNavigation direction="next" onClick={() => swiperRef.current.slideNext()} />
		</Swiper>
	);
}
