import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Button from '../Button'
import Div from '../Div'
import './post.scss'
import moment from 'moment'
import { Icon } from '@iconify/react';


export default function PostStyle2({ img, title, shortDesc, Desc, date, category, quote, categoryHref, href }) {
  const navigate = useNavigate();
  const handleNavigate = (e) => {
    e.preventDefault()
    navigate('/blog/blog-details', { state: { img:img, title:title, shortDesc:shortDesc, Desc:Desc, date:date, category:category, quote:quote, categoryHref:categoryHref, href:href } });
  }
 
  return (
    <Div className="cs-post cs-style2 blog_page_banner">
      <Link to={'/blog/blog-details'} onClick={(e) => handleNavigate(e)} className="cs-post_thumb cs-radius_15">
        <img src={process.env.REACT_APP_BASE_URL + img[0]} alt="Post" className="w-100 cs-radius_15" />
      </Link>
      <Div className="cs-post_info">
        <Div className="cs-post_meta cs-style1 cs-ternary_color cs-semi_bold cs-primary_font">
          <span className="cs-posted_by">{moment(date).format('DD-MMM-YYYY')}</span>
          <Link to={categoryHref} className="cs-post_avatar">{category}</Link>
        </Div>
 
        <h2 className="cs-post_title">{title}</h2>
        <Div className="cs-post_sub_title">{shortDesc && shortDesc}</Div>

        <Link to={'#'} onClick={(e) => handleNavigate(e)} className='cs-text_btn'>
          <>
            <span>See More</span><Icon icon="bi:arrow-right" />
          </>
        </Link>
      </Div>
    </Div>
  )
}
