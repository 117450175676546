import React, { useEffect, useState } from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import axios from "axios";
import {useContact} from "../../components/Context/Contactcontext";


export default function Footer({ copyrightText, logoSrc, logoAlt, text }) {
  const { contactdetails } = useContact();

  const copyrightLinks = [
    {
      title: 'Terms of Use',
      href: '/'
    },
    {
      title: 'Privacy Policy',
      href: '/'
    }
  ]

  useEffect(() => {
    getLatestService()
  }, [])

  const [latestService, setLatestService] = useState([])
  const getLatestService = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `service/GetRecentServicesPublic`).then((res) => {
      setLatestService(res.data.latestService)
    }).catch((error) => {
      console.log(error, "error");
    });
  };

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget
                  logoSrc={localStorage.getItem('mode') === 'dark' ? '/images/new/logo-dark.png': '/images/new/logo.png' }
                  logoAlt='Logo'
                  text='360° Tailored Creative and Data-driven Digital Solutions forging The Long and Lasting Impression.'
                />
                <SocialWidget contactdetails={contactdetails}/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={latestService} menuHeading='Services' />
              </Div>
            </Div>
            <Div className="col-lg-6 col-sm-12">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us' contactdetails={contactdetails} />
              </Div>
            </Div>
            {/* <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter
                  title='Subscribe'
                  subtitle='At vero eos et accusamus et iusto odio as part dignissimos ducimus qui blandit.'
                  placeholder='example@gmail.com'
                />
              </Div>
            </Div> */}
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2024 The Impression.</Div>
          </Div>
          {/* <Div className="cs-bottom_footer_right">
            <MenuWidget menuItems={copyrightLinks} variant=' cs-style2' />
          </Div> */}
        </Div>
      </Div>
    </footer>
  )
}
