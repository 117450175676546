import { Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ContactProvider } from "./components/Context/Contactcontext";
import AboutPage from './components/Pages/AboutPage';
import BlogPage from './components/Pages/BlogPage';
import BlogDetailsPage from './components/Pages/BlogDetailsPage';
import ContactPage from './components/Pages/ContactPage';
import ErrorPage from './components/Pages/ErrorPage';
import Home from './components/Pages/Home';
import PortfolioDetailsPage from './components/Pages/PortfolioDetailsPage';
import ProjectDetailsPage from './components/Pages/ProjectDetailsPage';

import ServiceDetailsPage from './components/Pages/ServiceDetailsPage';
import ServicesPage from './components/Pages/ServicesPage';
import TeamPage from './components/Pages/TeamPage';
import PortfolioPage from './components/Pages/PortfolioPage';
import GalleryPage from './components/Pages/GalleryPage';
import GalleryPageDetails from './components/Pages/GallerypageDetails';

import ProjectPage from './components/Pages/ProjectPage';
import Allservice from './components/Pages/Allservice';
import TeamDetails from './components/Pages/TeamDetails';
import PhotographyAgencyHome from './components/Pages/PhotographyAgencyHome';
import CreativePortfolioHome from './components/Pages/CreativePortfolioHome';
import DigitalAgencyHome from './components/Pages/DigitalAgencyHome';
import MarketingAgencyHome from './components/Pages/MarketingAgencyHome';
import ShowcasePortfolioHome from './components/Pages/ShowcasePortfolioHome';
import CaseStudyShowcaseHome from './components/Pages/CaseStudyShowcaseHome';
import Layout from './components/Layout';
import CaseStudyDetailsPage from './components/Pages/CaseStudyDetailsPage';
import FaqPage from './components/Pages/FaqPage';
import { Box } from '@mui/material';
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import CareerPage from './components/Pages/CareerPage';
import PortfolioDetailsPageLeadForge from './components/Pages/PortfolioDetailsPageLeadForge';
import DataMining from './components/Pages/DataMining';
import PortfolioDetailsPageX from './components/Pages/PortfolioDetailsPageX';



function App() {
  return (
    <Box sx={{ width: '100%', bgcolor: 'background.default', color: 'text.primary' }}>
      <ContactProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="photography-agency" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/photography-agency/" />
            </Helmet><PhotographyAgencyHome /></>} />


            <Route path="digital-agency" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/digital-agency/" />
            </Helmet><DigitalAgencyHome /></>} />
            <Route path="marketing-agency" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/marketing-agency/" />
            </Helmet><MarketingAgencyHome /></>} />
            <Route path="about" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/about/" />
            </Helmet><AboutPage /></>} />
            {/* <Route path="service" element={<ServicesPage />} /> */}

            {/* <Route path="allservice" element={<Allservice />} /> */}

            {/* <Route path="service/:serviceDetailsId" element={<ServiceDetailsPage />} /> */}

            {/* <Route path="portfolio" element={<PortfolioPage />} />
          <Route path="portfolio/:portfolioDetailsId" element={<PortfolioDetailsPage />} /> */}
            <Route path="service" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/service/" />
            </Helmet><PortfolioPage /></>} />
            <Route path="service/:serviceDetailsId" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/service/:serviceDetailsId/" />
            </Helmet><PortfolioDetailsPageX /></>} />
            <Route path="leadforge" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/leadforge/" />
            </Helmet><PortfolioDetailsPageLeadForge /></>} />
            <Route path="service/:d2g" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/service/:d2g/" />
            </Helmet><DataMining /></>} />
            <Route path="ourwork" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/ourwork/" />
            </Helmet><ProjectPage /></>} />
            <Route path="ourwork/:workDetailsId" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/ourwork/:workDetailsId/" />
            </Helmet><ProjectDetailsPage /></>} />
            <Route path='career' element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/career/" />
            </Helmet><CareerPage /></>} />
            <Route path="gallery" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/gallery/" />
            </Helmet><GalleryPage /></>} />
            <Route path="gallerytest" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/gallerytest/" />
            </Helmet><GalleryPageDetails /></>} />

            <Route path="blog" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/blog/" />
            </Helmet><BlogPage /></>} />
            <Route path="blog/:blogDetailsId" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/blog/:blogDetailsId/" />
            </Helmet><BlogDetailsPage /></>} />
            <Route path="contact" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/contact/" />
            </Helmet><ContactPage /></>} />
            <Route path="team" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/team/" />
            </Helmet><TeamPage /></>} />
            <Route path="team/:teamDetails" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/team/:teamDetails/" />
            </Helmet><TeamDetails /></>} />
            <Route path="/case-study/:caseStudyDetailsId" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me//case-study/:caseStudyDetailsId/" />
            </Helmet><CaseStudyDetailsPage /></>} />
            <Route path="faq" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/faq/" />
            </Helmet><FaqPage /></>} />
          </Route>
          <Route path="/" element={<><Helmet>
            <link rel="canonical" href="https://theimpression.me/" />
          </Helmet><Layout headerVariant="cs-site_header_full_width" /></>} >
            <Route path="creative-portfolio" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/creative-portfolio/" />
            </Helmet><CreativePortfolioHome /></>} />
            <Route path="showcase-portfolio" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/showcase-portfolio/" />
            </Helmet><ShowcasePortfolioHome /></>} />
            <Route path="case-study-showcase" element={<><Helmet>
              <link rel="canonical" href="https://theimpression.me/case-study-showcase" />
            </Helmet><CaseStudyShowcaseHome /></>} />
          </Route>
          <Route path="*" element={<><Helmet>
            <link rel="canonical" href="https://theimpression.me/*/" />
          </Helmet><ErrorPage /></>} />

        </Routes>
      </ContactProvider>
      <FloatingWhatsApp  
      // phoneNumber="+971562117034"    
        phoneNumber="+971 56 211 7034"
        accountName="Impression Team"
        avatar="/images/theimpressionProPic.jpg"
        allowEsc
        allowClickAway
        notification
        notificationSound />
    </Box>
  );
}

export default App;
