import { Box, CircularProgress } from '@mui/material'
import React from 'react'

export default function Loader_circular() {
  return (
    <Box className="loader_container">
        <CircularProgress className='primarytext'/>
    </Box>
    
  )
}
