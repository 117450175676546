import React, { useEffect, useState } from 'react'
import Slider from "react-slick";
import Div from '../Div';
import Post from '../Post';
import axios from 'axios';

export default function PostSlider() {
 
  useEffect(() => {
    getBlogs()
  }, [])
  const [blogs, setBlogs] = useState([])

  const getBlogs = (e) => {
    axios.get(process.env.REACT_APP_BASE_URL + `blog/getBlogsPublic`).then((res) => {
     
      setBlogs(res.data.getBlogs)
    }).catch((error) => {
      console.log(error, "error");
    });
  }


  /** Slider Settings **/
  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    // speed: 2000,
    slidesToShow:  blogs && blogs.length > 3 ? 3 : blogs.length === 3 ? 2 : blogs.length,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow:   blogs && blogs.length > 3 ? 3 : blogs.length === 3 ? 2 : blogs.length,
        }
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: blogs && blogs.length > 2 ? 2 : blogs.length,
        }
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: blogs && blogs.length > 2 ? 2 : blogs.length,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (<> 
   {blogs && blogs.length > 0 ? 
    <Slider {...settings} className={blogs.length < 3 ? 'cs-gap-24 blog_slider blog_slider_limit' : 'cs-gap-24 blog_slider'}>
      {blogs && blogs.map((item, index) => (
        <Div key={index}>
          <Post
            url={'/blog/blog-details'}
            src={item.Images[0]}
            img={item.Images}
            alt={item.Blog_Name}
            date={item.createdAt}
            title={item.Blog_Name}
            shortDesc={item.shortdescription}
            Desc={item.Description}
            category={item.Category && item.Category.category_name}
            quote={item.blogqoute}
            categoryHref={'/blog'}
            href={'/blog/blog-details'}
          />
        </Div>
      ))}
    </Slider>:
    <Div className='text-left'>
				   <h2 className="cs-cta_title cs-semi_bold cs-m0 no_data">No Blogs Found</h2>
    </Div>
          }
    </>
  )
}
