import React, { useEffect } from 'react';
import { pageTitle } from '../../helper';
import Cta from '../Cta';
import FunFact from '../FunFact';
import PageHeading from '../PageHeading';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import TeamSlider from '../Slider/TeamSlider';
import Spacing from '../Spacing';
import axios from 'axios';
import { useSelector } from 'react-redux';

export default function AboutPage() {
  pageTitle('About');
  const colordata = useSelector((state) => state && state.colordata)

  const funfaceData = [
    {
      title: 'Leads Generated',
      factNumber: '500K',
    },
    {
      title: 'Team Members',
      factNumber: '60',
    },
    {
      title: 'Projects Delivered',
      factNumber: '10k',
    },
    {
      title: 'Campaigns Spend',
      factNumber: '$50M',
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  useEffect(() => {
    getTeamMembers()
  }, [])

  const [teamList, setTeamList] = React.useState([])
  const getTeamMembers = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `team/getTeamPublic`).then((res) => {
      setTeamList(res.data.teamdata)
    }).catch((error) => {
      console.log(error, "error");
    });
  };

  return (
    <Div className="about_page">
      {/* Start Page Heading Section */}
      <PageHeading
        title="About Us"
        bgSrc="images/1--about-us-banner.jpg"
        pageLinkText="About Us"
      />
      {/* End Page Heading Section */}

      {/* Start About Section */}
      <Spacing lg="80" md="40" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="Digital Legacy Starts Here"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0" style={{color:colordata == 'light'? '#000':""}}>
                Driving brand futures through innovation, individuality, and social responsibility.</p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0" style={{color:colordata == 'light'? '#000':""}}>The Impression is at the forefront of digital evolution. We're committed to evolving international businesses, forging impactful partnerships, and delivering growth across all sectors.
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-5 offset-xl-2">
            <img
              src="/images/about_us.png"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          {/* <Div className="col-lg-7">
            <img
              src="/images/about_img_2.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div>
          <Div className="col-lg-5">
            <img
              src="/images/about_img_3.jpeg"
              alt="About"
              className="w-100 cs-radius_15"
            />
            <Spacing lg="25" md="25" />
          </Div> */}
        </Div>
      </Div>
      <Spacing lg="75" md="20" />
      {/* End About Section */}

      {/* Start Fun Fact Section */}
      <Div className="container">
        <FunFact
          title="Our Fun Facts"
          subtitle="Who said numbers have to be boring? We turn them into stories. Welcome to the narrative!"
          data={funfaceData}
        />
      </Div>
      {/* End Fun Fact Section */}

      {/* Start Why Choose Section */}
      <Spacing lg="100" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-6">
            <Div className="cs-image_layer cs-style1">
              <Div className="cs-image_layer_in">
                <img
                  src="/images/about_img_4.jpeg"
                  alt="About"
                  className="w-100 cs-radius_15"
                />
              </Div>
            </Div>
            <Spacing lg="0" md="40" />
          </Div>
          <Div className="col-xl-5 offset-xl-1 col-lg-6">
            <SectionHeading
              title="Why Partner with Us?"
              subtitle=""
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0" style={{color:colordata == 'light'? '#000':""}}>
                Our approach is holistic and data-driven, ensuring that every strategy is grounded in real-world insights and tailored to your specific goals. We're not just consultants; we're your strategic partners, dedicated to navigating the complexities of the digital landscape together.
              </p>
              <Spacing lg="15" md="15" />
              <p className="cs-m0" style={{color:colordata == 'light'? '#000':""}}>
                With us, you gain more than just a service provider; you gain allies committed to your success. Our track record speaks for itself: a history of transforming challenges into opportunities and businesses into benchmarks of their industries.
              </p>
              <Spacing lg="15" md="15" />
              <p style={{color:colordata == 'light'? '#000':""}}>Choose us and let's contribute to your Long and Lasting IMPRESSION.</p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="0" />
            </SectionHeading>
          </Div>
        </Div>
      </Div>
      {/* End Why Choose Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container team_slider">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        {teamList && teamList.length > 0 ?
          <TeamSlider teamList={teamList} /> :
          <>
            <h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">No team member found</h2>
            <Spacing lg="90" md="45" />
          </>
        }
      </Div> */}
      {/* End Team Section */}

      {/* Start CTA Section */}
      <Spacing lg="100" md="30" />
      <Div className="container cta_bg_image">
        <Cta
          // title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
          title="Let’s Create <br />something Remarkable!"
					btnText="Download Our Portfolio"
					btnLink="https://api.theimpression.me/public/Theimpession-companyprofile.pdf"
					bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      {/* End CTA Section */}
    </Div>
  );
}
