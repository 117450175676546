import React, { useCallback, useEffect, useState } from "react";
import { Link, NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import SocialWidget from "../Widget/SocialWidget";
import Newsletter from "../Widget/Newsletter";
import "./header.scss";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import Div from "../Div";
import DropDown from "./DropDown";
import axios from "axios";
import ToggleColorMode from "../../DarkLight_mode/ToggleColorMode";
import { useContact } from "../../components/Context/Contactcontext";
import { Icon } from '@iconify/react';
import { Button } from "@mui/material";
import { debounce, throttle } from 'lodash';
import Loader_circular from "../Loader";


export default function Header({ variant }) {
	const params = useLocation()
	const navigate = useNavigate();
	const [isSticky, setIsSticky] = useState(false);
	const [sideHeaderToggle, setSideHeaderToggle] = useState(false);
	const [mobileToggle, setMobileToggle] = useState(false);
	const { contactdetails } = useContact();

	useEffect(() => {
		window.addEventListener("scroll", () => {
			if (window.scrollY > 0) {
				setIsSticky(true);
			} else {
				setIsSticky(false);
			}
		});
	}, []);

	useEffect(() => {
		getCategory()
		getcategorywithSerice()
	}, []);

	const getcategorywithSerice = async () => {
		try {
			// await axios.get(process.env.REACT_APP_BASE_URL + `category/CategoryWithServices`).then((res) => {
			// 	console.log(res.data, 'getcategorywithSerice');
			// }).catch((error) => {
			// 	console.log(error, "error");
			// });
			const response = await axios.get(process.env.REACT_APP_BASE_URL + 'category/CategoryWithServices');
			const data = response.data;
			data.categoriesWithServices.forEach(category => {
				localStorage.setItem(category._id, JSON.stringify(category));
			});			
		} catch (error) {
			console.log(error, 'error');
		}
	}

	const [categoryList, setCategoryList] = React.useState([])
	const getCategory = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `category/AllCategories`).then((res) => {
			setCategoryList(res.data.Categories)
		}).catch((error) => {
			console.log(error, "error");
		});
	};

	
	const [subCatList, setSubCatList] = useState([])
	const [service, setService] = useState([])
	const [catPopup, setCatPop] = useState(false)
	const [subCatPopup, setsubCatPop] = useState(false)
	const [servicemenu, setServicemenu] = useState(false)
	const [mainmenu, setMainmenu] = useState(false)

	// const callbackFunction = (id, prop) => {
	// 	axios.get(process.env.REACT_APP_BASE_URL + `category/Getservices/${id}`).then((res) => {
	// 		setService(res.data.services)
	// 	}).catch((error) => {
	// 		console.log(error, "error");
	// 		setSubCatList([])
	// 	});
	// }

	const [subService, setSubService] = useState(false)
	const [selectedCat, setSelectedCat] = useState('')
	const [selectedsubCat, setSelectedsubCat] = useState('')
	const handleMouseOverOnSubcat = (e, item, props) => {
		setSelectedsubCat(item)
		setSubService(true)
		setServicemenu(false)
		axios.get(process.env.REACT_APP_BASE_URL + `category/GetServicebyid?categoryId=${item.categoryId}&subcategoryId=${item.subcategoryId}`)
		.then((res) => {
			setService(res.data.services)
			if (props === 'mbl') {
				navigate('/service', { state: { categoryId: item.categoryId && item.categoryId, subcategoryId: item.subcategoryId && item.subcategoryId } });
			}
		}).catch((error) => {
			console.log(error, "error");
			setService([])
		});
	}

	const handleMouseOver1 = (e, item, prop) => {		
		e.preventDefault()
		console.log(item,"itemitem")
		// Retrieve data from local storage based on category._id
		const categoryData = JSON.parse(localStorage.getItem(item._id));
		// Now you have categoryData available for further use		
		setService(categoryData && categoryData.services)
		
	};

	



	const handleMouseOver = (e, item, prop) => {
		e.preventDefault()
		setSelectedCat(item)
		setService([])
		setCatPop(false)
		setMainmenu(false)
		setServicemenu(true)
		axios.get(process.env.REACT_APP_BASE_URL + `category/Getservices/${item._id}`).then((res) => {
			setService(res.data.services)
		}).catch((error) => {
			console.log(error, "error");
		});
	};

	const handleClickMenu = (e, service, serviceIndex) => {
		e.preventDefault()

		if(service[0].service_name === "LeadForge"){
			console.log("called")

		setMobileToggle(false)
		navigate('/leadforge', { state: { index: serviceIndex, data: service } });
		return;
		}

		if(service[0].service_name === "Data Mining" || service[0].service_name === "Advisory Consulting"){
			console.log("called")
		setMobileToggle(false)
		navigate('/service/d2g', { state: { index: serviceIndex, data: service } });
		return;
		}
		
		setMobileToggle(false)
		navigate('/service/service-details', { state: { index: serviceIndex, data: service } });
		console.log("called")

	}

	// const handleCategoryMenu = (e, item) => {

	// 	e.preventDefault()
	// 	setMobileToggle(false)
	// 	navigate('/service', { state: { categoryId: item._id && item._id, subcategoryId: 'all' } });
	// }

	// const handleSubsategoryMenu = (e, item) => {
	// 	e.preventDefault()
	// 	setMobileToggle(false)
	// 	navigate('/service', { state: { categoryId: item.categoryId && item.categoryId, subcategoryId: item.subcategoryId && item.subcategoryId } });
	// }

	const handleClickMobileCat = () => {
		setCatPop(true)
		setMainmenu(false)
		setServicemenu(false)
	}

	const handleMobileCloseBtn = (e) => {
		e.preventDefault()
		setMobileToggle(!mobileToggle);
		setCatPop(false);
		setServicemenu(false);
		setMainmenu(true);
		setService([]);
		setSelectedCat('');
		setSelectedsubCat('');
		// navigate('/service', { state: { categoryId: 'all', subcategoryId: 'all' } });
	}

	const handlebackSubcat = (e) => {
		e.preventDefault()
		setServicemenu(true);
		setService([]);
		setSubService(false);
		setSelectedsubCat('')
		navigate('/service', { state: { categoryId: selectedCat._id, subcategoryId: 'all' } });
	}

	const handlebackCat = (e) => {
		setCatPop(true);
		setService([])
		// navigate('/service', { state: { categoryId: 'all', subcategoryId: 'all' } });
	}
	const handleNavigate = (e) => {
		e.preventDefault()
		navigate('/', { state: {} });
		setMobileToggle(false);
		setCatPop(false);
		setServicemenu(false);
		setMainmenu(false);
		setService([]);
		setSelectedCat('');
		setSelectedsubCat('');
	}

	if (params && params.pathname === '/service/service-details') {
		var d = document.getElementById("custom_menuID");
		if (d) {
			d.classList.add("active");
		} else {
			console.error("Element with ID 'custom_menuID' not found.");
		}
	} else {
		var d = document.getElementById("custom_menuID");
		if (d) {
			d.classList.remove("active");
		} else {
			console.error("Element with ID 'custom_menuID' not found.");
		}
	}




	

	return (
		<>
			<header className={`cs-site_header cs-style1 text-uppercase ${variant ? variant : ""} cs-sticky_header ${isSticky ? "cs-sticky_header_active" : ""}`}>
				<Div className="cs-main_header">
					<Div className="container">
						<Div className="cs-main_header_in">
							<Div className="cs-main_header_left">
								<Link className="cs-site_branding" to='./' onClick={(e) => handleNavigate(e)}>
									<img src={'/images/new/logo-dark.png'} alt="Logo" onClick={(e) => handleNavigate(e)} />
								</Link>
							</Div>
							<Div className="cs-main_header_center">
								<Div className="cs-nav cs-primary_font cs-medium">
									<ul className="cs-nav_list header_main_menu desktop_menu" style={{ display: `${mobileToggle ? "block" : "none"}` }}>
										{/* ------Desktop----- */}
										<li className=""><NavLink to="/" onClick={() => setMobileToggle(false)}> Home </NavLink></li>
										<li><NavLink to="about" onClick={() => setMobileToggle(false)}>About</NavLink></li>
										<li className="menu-item-has-children">
											<span id="custom_menuID" className="custom_menu" onClick={() => setMobileToggle(false)}>
												Services <Icon icon="uiw:down" className="ml-10" />
											</span>
											<DropDown >
												<ul>
													{categoryList && categoryList.map((item, i) => (
														<li className="menu-item-has-children" key={i}>
															{/* <div> <span onMouseOver={(e) => { handleMouseOver(e, item, 'web') }} >{item.category_name} </span><Icon className="float_ryt" icon="oui:arrow-right" /></div> */}
															<div style={{ textTransform: 'none' }}>
																<span onMouseOver={(e) => { handleMouseOver1(e, item, 'web') }} >{item.category_name && item.category_name.includes("D2G") ? "D2G (Digital to Government)" :
																	item.category_name.includes("D2B") ? 'D2B (Digital to Business)' :
																		item.category_name.includes("D2C") ? 'D2C (Digital to Consumer)' :
																			item.category_name}
																</span><Icon className="float_ryt" icon="oui:arrow-right" />
															</div>
															<DropDown>
																<ul>
																	<>
																		{service ? service.length > 0 ? service.map((item, i) => (
																			<li key={i}>
																				<Link onClick={(e) => handleClickMenu(e, service, i)}>{item.service_name.replace(/\b(?:And|and)\b/g, "&")}
																				</Link>
																			</li>
																		)) : '' : ''}
																	</>
																</ul>
															</DropDown>
														</li>
													))}
												</ul>
											</DropDown>
										</li>
										<li className=""><NavLink to="ourwork" onClick={() => setMobileToggle(false)}>Our Work</NavLink></li>
										<li className=""><NavLink to="career" onClick={() => setMobileToggle(false)}>Careers</NavLink></li>
										{/* <li><NavLink to="gallery" onClick={() => setMobileToggle(false)}>Gallery</NavLink></li> */}
										<li><NavLink to="contact" onClick={() => setMobileToggle(false)}> Contact Us </NavLink></li>
									</ul>

									
									{/* ------mobile----- */}
									<ul className="cs-nav_list header_main_menu mob_menu" style={{ display: `${mobileToggle ? "block" : "none"}` }}>
										{mainmenu ?
											<>
												<li className=""><NavLink to="/" onClick={() => setMobileToggle(false)}> Home </NavLink></li>
												<li><NavLink to="about" onClick={() => setMobileToggle(false)}>About</NavLink></li>
												<li className="menu-item-has-children"><div className="custom_menu_white" onClick={() => handleClickMobileCat()}><div>Services</div> <Icon icon="ic:round-keyboard-arrow-right" />
												</div>
												</li>
												<li className=""><NavLink to="ourwork" onClick={() => setMobileToggle(false)}>Our Work</NavLink></li>
												<li><NavLink to="career" onClick={() => setMobileToggle(false)}>Careers</NavLink></li>
												<li><NavLink to="contact" onClick={() => setMobileToggle(false)}> Contact Us </NavLink></li>
											</>
											: catPopup ?
												<><div className='menu_breadcrumbs'>
													<Icon onClick={(e) => { setMainmenu(true); setCatPop(false); setService([]) }}
														className="back_arrow" icon="oui:arrow-left" />
													<h3>Services</h3></div>
													{categoryList && categoryList.map((item, i) => (
														<>
															<li key={i} >
																<Link onClick={(e) => handleMouseOver(e, item, 'mbl')}>{item.category_name} <Icon className="float_ryt" icon="ic:round-keyboard-arrow-right" />
																</Link>
															</li>
														</>
													))}
												</>
												: servicemenu ? <>
													<div className='menu_breadcrumbs'>
														<Icon onClick={(e) => handlebackCat(e)}
															className="back_arrow" icon="oui:arrow-left" />
														<h3>{selectedCat && selectedCat.category_name}</h3>
													</div>
													{subCatList && subCatList.length > 0 ? <>
														{subCatList && subCatList.map((item, i) => (
															<li key={i} className="menu-item-has-children">
																<Link onClick={(e) => handleMouseOverOnSubcat(e, item, 'mbl')}>{item.subcategoryName.replace(/\b(?:And|and)\b/g, "&")} <Icon className="float_ryt" icon="ic:round-keyboard-arrow-right" />
																</Link>
															</li>
														))}</> : <>
														{service.map((item, i) => (
															<li key={i} className="menu-item-has-children" style={{ listStyle: 'circle', marginLeft: 30 }} >
																<Link onClick={(e) => handleClickMenu(e, service, i)} style={{ textTransform: 'capitalize' }}>{item.service_name.replace(/\b(?:And|and)\b/g, "&")}
																</Link>
															</li>
														))}
													</>}
												</> : subService ? <>
													<div className='menu_breadcrumbs'>
														<div>
															<Icon onClick={(e) => handlebackCat(e)}
																className="back_arrow" icon="oui:arrow-left" />
															<h3>{selectedCat && selectedCat.category_name}</h3>
														</div>
														<div>
															<Icon onClick={(e) => handlebackSubcat(e)}
																className="back_arrow" icon="oui:arrow-left" />
															<h3>{selectedsubCat && selectedsubCat.subcategoryName}</h3>
														</div>
													</div>
													{service.map((item, i) => (
														<li key={i} className="menu-item-has-children" style={{ listStyle: 'circle', marginLeft: 30 }}>
															<Link onClick={(e) => handleClickMenu(e, service, i)} style={{ textTransform: 'capitalize' }}>{item.service_name}
															</Link>
														</li>
													))}
												</> : ''}

									</ul>
									<span className={mobileToggle ? "cs-munu_toggle cs-toggle_active" : "cs-munu_toggle"} onClick={(e) => handleMobileCloseBtn(e)}><span></span></span>
								</Div>
							</Div>
							<Div className="cs-main_header_right">
								<Div className="cs-toolbox mr-10 theme_mode">
									<span className="cs-icon_btn ">
										<ToggleColorMode />
									</span>
								</Div>
								<Div className="cs-toolbox ">
									<span className="cs-icon_btn" onClick={() => setSideHeaderToggle(!sideHeaderToggle)}>
										<span className="cs-icon_btn_in"><span /><span /><span /><span /></span>
									</span>
								</Div>
							</Div>
						</Div>
					</Div>
				</Div>
			</header>

			<Div className={sideHeaderToggle ? "cs-side_header active" : "cs-side_header"}>
				<button className="cs-close" onClick={() => setSideHeaderToggle(!sideHeaderToggle)} />
				<Div className="cs-side_header_overlay" onClick={() => setSideHeaderToggle(!sideHeaderToggle)} />
				<Div className="cs-side_header_in">
					<Div className="cs-side_header_shape" />
					<Link className="cs-site_branding" to="/">
						<img src={localStorage.getItem('mode') === 'dark' ? "/images/new/logo-dark.png" : "/images/new/logo.png"} alt="Logo" />
					</Link>
					<Div className="cs-side_header_box">

						<h2 className="cs-side_header_heading">360° Tailored Creative and Data-driven Digital Solutions forging <br /> The Long and Lasting Impression.</h2>
					</Div>
					<Div className="cs-side_header_box">
						<ContactInfoWidget title="Contact Us" withIcon contactdetails={contactdetails} />
					</Div>
					<Div className="cs-side_header_box">
						<SocialWidget contactdetails={contactdetails} />
					</Div>
				</Div>
			</Div>
		</>
	);
}