import { Typography } from "@mui/material";
import { Icon } from '@iconify/react';


export default function CardComponent ({ icon,title, text }) {
    return(
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      border: '1px solid #ddd',
      borderRadius: '8px',
      padding: '10px',
      width: '150px',
      boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
      textAlign: 'center',
    }}>
      {/* <img
        src={icon}
        alt={text}
        style={{ width: '100px', height: '100px' }}
      /> */}
      <Icon icon={icon} />
      <div className="cs-page_title cs-font_50" style={{ fontWeight:700, fontSize: '18px' }}>{title}</div>
      <p style={{ marginTop: '10px', fontSize: '14px' }}>{text}</p>
    </div>
    )
}
    
  