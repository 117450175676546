import React, { useEffect, useRef } from 'react';
import Div from '../Div';
import './logolist.scss';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation } from 'swiper';
import SwiperCore from 'swiper';
import 'swiper/swiper-bundle.min.css';
import axios from 'axios';
import CustomNavigation from '../CustomNavigation';

SwiperCore.use([Navigation]);

export default function LogoList() {
  const swiperRef = useRef(null);

  useEffect(() => {
    getPartnerLogo();
  }, []);

  const [partnerLogo, setPartnerLogo] = React.useState([]);

  const getPartnerLogo = async () => {
    try {
      const response = await axios.get(process.env.REACT_APP_BASE_URL + 'ourplatform/getOurpatnersPublic');
      setPartnerLogo(response.data.getourPlatform);
    } catch (error) {
      console.log(error, 'error');
    }
  };

  return (
    <Div className="cs-partner_logo_wrap">
      {partnerLogo && partnerLogo.length > 0 ? (
        <Swiper
          navigation={false} 
          onSwiper={(swiper) => (swiperRef.current = swiper)}
          modules={[Navigation]}
          className="mySwiper partner_slider"
          slidesPerGroup={5}
          slidesPerView={5}
          loop={true}
          breakpoints={{
            320: {
              slidesPerView: partnerLogo && partnerLogo.length > 1 ? 1 : partnerLogo.length,
              spaceBetween: 0,
              slidesPerGroup: 2,
            },
            400: {
              slidesPerView: partnerLogo && partnerLogo.length > 2 ? 2 : partnerLogo.length,
              spaceBetween: 0,
              slidesPerGroup: 2,
            },
            768: {
              slidesPerView: partnerLogo && partnerLogo.length > 3 ? 3 : partnerLogo.length,
              spaceBetween: 0,
            },
            992: {
              slidesPerView: partnerLogo && partnerLogo.length > 4 ? 4 : partnerLogo.length,
              spaceBetween: 0,
            },
            1100: {
              slidesPerView: partnerLogo && partnerLogo.length > 5 ? 5 : partnerLogo.length,
              spaceBetween: 0,
            },
          }}
        >
          {/* {partnerLogo.map((partner, index) => {
            
              return(
            <SwiperSlide className="cs-partner_logo" key={index}>
              <img src={process.env.REACT_APP_BASE_URL + partner.Logo} alt={'logo'} />
            </SwiperSlide>
          )})} */}
          {partnerLogo.map((partner, index) => {
  const logoSrc = process.env.REACT_APP_BASE_URL + partner.Logo;
  const specificLogo = '1728308482895-Blue2x-201116-151556.png'; // Use only the filename for includes check

  return (
    <SwiperSlide className="cs-partner_logo" key={index}>
      {logoSrc.includes(specificLogo) ? (
        <a href="https://www.activecampaign.com/?_r=SSFGRVE7" target="_blank" rel="noopener noreferrer">
          <img src={logoSrc} alt={'logo'} />
        </a>
      ) : (
        <img src={logoSrc} alt={'logo'} />
      )}
    </SwiperSlide>
  );
})}

          {/* Render custom navigation */}
          <CustomNavigation direction="prev"onClick={() => swiperRef.current.slidePrev()} />
          <CustomNavigation direction="next" onClick={() => swiperRef.current.slideNext()} />
        </Swiper>
      ) : (
        <Div className="text-left">
          <h2 className="cs-cta_title cs-semi_bold cs-m0 no_data">No Partner's Found</h2>
        </Div>
      )}
    </Div>
  );
}
