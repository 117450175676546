import { Icon } from '@iconify/react';
import React from 'react';

import Timeline from '../Timeline';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination } from 'swiper'
import 'swiper/css/pagination';

export default function TimelineSlider() {
	const timelineData = [
		[
			{
				year: '2019',
				name: 'Google awards',
				position: 'Website of the day',
				type: 'Mobile exelence',
			},
			{
				year: '2021',
				name: 'CSS awards design',
				position: 'Honorable mention',
				type: 'Desktop exelence',
			},
		],
		[
			{
				year: '2020',
				name: 'New technology innovation',
				position: 'Honorable mention',
				type: 'Desktop exelence',
			},
			{
				year: '2022',
				name: 'UI/UX design of the month',
				position: 'Website of the day',
				type: 'Mobile exelence',
			},
		],

	];



	return (
		// <Slider {...settings} className="cs-arrow_style3">
		//   {timelineData.map((item, index) => (

		//     <Div key={index}>
		//       <Timeline columnData={item} />
		//     </Div>
		//   ))}
		// </Slider>
		<Swiper
			modules={[Pagination]}
			className="mySwiper "
			slidesPerView={2}
			pagination={{
				clickable: true,
			}}
			breakpoints={{
				340: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				500: {
					slidesPerView: 2,
					spaceBetween: 0,
				}
			}}
		>

			{timelineData.map((item, index) => (
				<SwiperSlide className="" key={index}>
					<Timeline columnData={item} />
				</SwiperSlide>
			))}

		</Swiper>

	);
}
