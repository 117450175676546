import { Icon } from "@iconify/react";
import axios from "axios";
import React, { useEffect, useRef } from "react";
import { pageTitle } from "../../helper";
import Div from "../Div";
import PageHeading from "../PageHeading";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import ContactInfoWidget from "../Widget/ContactInfoWidget";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Box from '@mui/material/Box';
import { useSelector } from "react-redux"
import { ToastContainer, toast } from "react-toastify";
import { TextareaAutosize, Grid } from "@mui/material";
import CardComponent from "../Cardcomponent";
import Cta from "../Cta";

export default function CareerPage() {
	const cardData = [
		{ id: 1, icon: 'hugeicons:ai-innovation-01', title: 'Innovation', text: 'We constantly strive to find creative solutions.' },
		{ id: 2, icon: 'fluent:hat-graduation-12-regular', title: 'Excellence', text: 'We aim to exceed expectations in everything we do.' },
		{ id: 3, icon: 'pepicons-pencil:handshake', title: 'Integrity', text: 'Transparency and trust are at the heart of our work.' },
		{ id: 4, icon: 'mingcute:group-3-line', title: 'Teamwork', text: 'Collaboration is key to our success.' },
	];
	const [contactdetails, setContactDetails] = React.useState([]);
	const [phonenumber, setPhonenumber] = React.useState();
	const [buttonview, setButtonview] = React.useState(false);
	const colordata = useSelector((state) => state && state.colordata)
	const [contactdetl, setContactdetl] = React.useState({ fullnamename: "", email: "", position: "", phonenumber: "", coverletter: '', linkedin: '' });
	const [mapview, setMapview] = React.useState('');
	const [profileImage, setProfileImage] = React.useState('');
	const [profile, setProfile] = React.useState('')
	const [statesingle, setStatesingle] = React.useState([])
	const [statesingle2, setStatesingle2] = React.useState([])
	const [resumePreview, setResumePreview] = React.useState('');
	const [positions,setPositions] = React.useState([])
	const [errror, setError] = React.useState({ fullnamename: "", email: "", position: "", phonenumber: "", coverletter: '', linkedin: '' });
	const [serviceavlb, setServiceavlb] = React.useState({
		branding: false,
		campaigns_360: false,
		content_creation: false,
		digital_strategy_media_buying: false,
		social_media_management: false,
		research_development: false,
		data_mining: false,
		lead_generation: false,
		websites_applications: false,
		advisory_consulting_services: false,
	});
	pageTitle("Contact Us");

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	useEffect(() => {
		getContactDetails();
	}, []);

	const getContactDetails = async () => {
		await axios.get(process.env.REACT_APP_BASE_URL + `admincontact/getAdmindetailsPublic`).then((res) => {
			setContactDetails(res.data.admindetails.contactdetails);
			setMapview(res.data.admindetails.contactdetails && res.data.admindetails.contactdetails?.length > 0 && res.data.admindetails.contactdetails[0].googlemap)
		}).catch((error) => {
			console.log(error, "error");
		});
	};

	useEffect(()=>{
		GetPosition()
	},[])


	const GetPosition = () => {
		axios.get(process.env.REACT_APP_BASE_URL + `positions/getPositions`)
		.then((res)=>{
			setPositions(res.data.getPositions)
		})
		.catch((error)=>{
			console.log(error,"error in GetPosition");
		})
	}


	


	const handleContactdetail = (e) => {
		setContactdetl({ ...contactdetl, [e.target.name]: e.target.value });
		setError({ ...errror, [e.target.name]: "" });
	};
	const handleServicedetail = (e) => {
		setServiceavlb({ ...serviceavlb, [e.target.name]: e.target.checked });
	};

	const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
	const validate = () => {
		if (!contactdetl.fullname && !contactdetl.email && !contactdetl.phonenumber && !contactdetl.position) {
			setError({ ...errror, name: 'This field is required', email: 'This field is required', phonenumber: 'This field is required', position: 'This field is required' })
			return false
		}
		if (!contactdetl.fullname) {
			setError({ ...errror, name: "This field is required" });
			return false;
		}
		if (!contactdetl.email) {
			setError({ ...errror, email: "This field is required" });
			return false;
		}
		if (emailRegex.test(contactdetl.email) == false) {
			setError({ ...errror, email: "Please enter valid email" });
			return false;
		}
		if (!contactdetl.phonenumber) {
			setError({ ...errror, phonenumber: "This field is required" });
			return false;
		}
		if (!contactdetl.position) {
			setError({ ...errror, message: "This field is required" });
			return false;
		}
		return true;
	};

	const handleClik = (data, index) => {
		const updatedList = contactdetails.map((item, i) => ({
			...item,
			selected: i === index,
		}));
		setContactDetails(updatedList);
		setMapview(data.googlemap);
	};



	const handleSubmit = (event) => {
		event.preventDefault();
		var valid = validate();
		if (valid == true) {

			let formdata = new FormData()
			formdata.append('fullName', contactdetl.fullname)
			formdata.append('emailAddress', contactdetl.email)
			formdata.append('phoneNumber', contactdetl.phonenumber)
			formdata.append('position', contactdetl.position)
			formdata.append('resume', contactdetl.resume)
			formdata.append('portfolio', profile ? profile : '')
			formdata.append('coverLetter', contactdetl.coverletter)
			formdata.append('linkedInProfile', contactdetl.linkedin)
			setButtonview(true)
			axios.post(process.env.REACT_APP_BASE_URL + "ApplicationForm/ApplicationForm", formdata)
				.then((res) => {
					console.log(res.data, "SUCCESS");
					setButtonview(false)
					setContactdetl({ fullname: "", email: "", position: "", phonenumber: "", resume: '', coverletter: '', linkedin: '' });
					setProfileImage('')
					setProfile('')
					
					setResumePreview('')
					toast.success('Success')
				}).catch((err) => {
					setButtonview(false)
					console.log(err);
				});
		}
	};
	function displayAddress() {
		var element = document.getElementById("showhover");
		element.classList.add("cs-style1 ");
	}
	function hideAddress() {
		var element = document.getElementById("showhover");
		element.classList.remove("cs-style1");
	}

	const Card = ({ text }) => (
		<div className="bg-black rounded-lg flex flex-col items-center justify-center w-40 h-40 shadow-lg">
			<div className="w-16 h-16 rounded-full bg-pink-500 mb-4"></div>
			<span className="text-white text-center text-sm">{text}</span>
		</div>
	);


	//Resume upload:
	const handleFileChange = (event) => {
		const file = event.target.files[0];
		if (file && file.type === 'application/pdf') {
			setContactdetl(prevDetails => ({
				...prevDetails,
				resume: file
			}));
			setResumePreview(URL.createObjectURL(file));
		} else {
			alert('Please upload a valid PDF file.');
		}
	};

	//Portofolio upload:
	const handleImageFileChange = (e) => {
		const PreviewImage = URL.createObjectURL(e.target.files[0])
		const file = e.target.files[0];
		if (PreviewImage && file) {
			setProfileImage(URL.createObjectURL(file));
			setProfile(file)
		}
	};

	const fullNameInputRef = useRef(null);

	// Function to focus the Full Name input
	const focusFullNameInput = () => {
		if (fullNameInputRef.current) {
			fullNameInputRef.current.focus();
		}
	};

	const scrollToContactPage = () => {
		focusFullNameInput();
	};


	return (
		<Box className="careerPage">
			<Div
				className="cs-page_heading cs-style1 cs-center text-center cs-bg"
				style={{ backgroundImage: `url(${"/images/career_hero_bg.jpg"})` }}
			>
				<Div className="container">
					<Div className="cs-page_heading_in">
						<h1 className="cs-page_title cs-font_50 cs-white_color">Join The Impression Team – Where Innovation Meets Excellence</h1>
						<h4>At The Impression, we strive to be at the forefront of creative marketing and lead generation strategies. Discover your potential with us.</h4>
					</Div>
				</Div>
			</Div>
			<Spacing lg="80" md="40" />

			<Div className="container">
				<Div className="cs-page_heading_in">
					<h4 className="light_pink">Why Work With Us</h4>
				</Div>

				<div className="work_card" style={{
					display: 'flex',
					justifyContent: 'space-around',
					padding: '20px',

				}}>
					{cardData.map(card => (
						<CardComponent key={card.id} icon={card.icon} title={card.title} text={card.text} />
					))}
				</div>
			</Div>
			<Spacing lg="80" md="40" />


			<Div className="container contact_page cs-shape_wrap_4">
				<Div className="cs-shape_4"></Div>
				<Div className="cs-shape_4"></Div>
				<Div className="row">
					<Div className="col-lg-6">
						<SectionHeading
							title="Apply Now"
							subtitle=""
						/>
						<Spacing lg="55" md="10" />

						<Spacing lg="55" md="10" />


					</Div>
					<Div className="col-lg-6">
						<form className="row" >
							<Div className="col-sm-6">
								<label className="cs-primary_color">Full Name*</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.fullname}
									name="fullname"
									onChange={handleContactdetail}
									ref={fullNameInputRef}
								/>
								<p style={{ color: "red" }}> {errror.fullname}</p>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Email Address*</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.email}
									name="email"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.email}</p>
								<Spacing lg="20" md="20" />
							</Div>
							<Div className="col-sm-6">
								<label className="cs-primary_color">Phone Number*</label>
								<PhoneInput
									value={contactdetl.phonenumber}
									className="cs-form_field"
									defaultCountry="LB"
									onChange={(e) => {
										setContactdetl({ ...contactdetl, phonenumber: e });
										setError({ ...errror, phonenumber: "" });
									}}
								/>
								<p style={{ color: "red" }}> {errror.phonenumber}</p>
								<Spacing lg="20" md="20" />
							</Div>

							<Div className="col-sm-6">
								<label className="cs-primary_color">Position Applying For</label>
								<select
									className="cs-form_field"
									value={contactdetl.position}
									name="position"
									onChange={handleContactdetail}
									>
									{positions && positions.length > 0 ? (
										<>
										<option value="" disabled>Select a position</option>
										{positions.map((item, i) =>
											item.positions?.map((data, index) => (
											<option key={`${i}-${index}`} value={data}>{data}</option>
											))
										)}
										</>
									) : (
										<option value="" disabled>No positions found</option>
									)}
								</select>
								<p style={{ color: "red" }}> {errror.position}</p>
								<Spacing lg="20" md="20" />
							</Div>


							<Div className="col-sm-12">
								<Div className="upload-preview-container">
									<Box className="d-flex align-items-center flex-wrap justify-content-between w-100">
										<label className="cs-primary_color">Upload Resume (PDF only)</label>

										<label htmlFor="file-upload" className="upload-button cs-btn cs-style1">
											<span>Choose a File</span>
											<input
												id="file-upload"
												type="file"
												accept=".pdf"
												onChange={handleFileChange}
												className="cs-form_field"
											/>
										</label>
									</Box>
									{resumePreview && (
										<iframe
											src={resumePreview}
											title="Resume Preview"
											width="300px" // Adjust width as needed
											height="200px"
											style={{ border: 'none' }}
										/>
									)}
									<Spacing lg="20" md="20" />
								</Div>
							</Div>
							<Div className="col-sm-12">
								<Div className="upload-preview-container">
									<Box className="w-100 d-flex align-items-center flex-wrap justify-content-between">
										<label className="cs-primary_color">Upload Portofolio</label>

										<label htmlFor="image-upload" className="upload-button cs-btn cs-style1">
											<span>Choose a File</span>
											<input
												id="image-upload"
												type="file"
												accept=".png,.png,.jpg"
												onChange={(e) => handleImageFileChange(e)}
												className="cs-form_field"
											/>
										</label>
									</Box>
									<Grid style={{ marginTop: 5 }} container columnSpacing={2} rowSpacing={4} >
										{profileImage &&
											
												<img src={profileImage} width='200px' height={150} />
										

										}
									</Grid>
									<Spacing lg="20" md="20" />
								</Div>
							</Div>
							<Spacing lg="20" md="20" />

							<Div className="col-sm-6">
								<label className="cs-primary_color">Cover Letter</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.coverletter}
									name="coverletter"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.email}</p>
								<Spacing lg="20" md="20" />
							</Div>


							<Div className="col-sm-6">
								<label className="cs-primary_color">Linkedin Profile</label>
								<input
									type="text"
									className="cs-form_field"
									value={contactdetl.linkedin}
									name="linkedin"
									onChange={handleContactdetail}
								/>
								<p style={{ color: "red" }}> {errror.email}</p>
								<Spacing lg="20" md="20" />
							</Div>










						</form>
						<Spacing lg="20" md="20" />
						<Div className="col-sm-12">
							{buttonview == false ?
								<button className="cs-btn cs-style1" onClick={(e) => handleSubmit(e)}>
									<span>Submit</span>
									<Icon icon="bi:arrow-right" />
								</button> :
								<button className="cs-btn cs-style1">
									<span>Please wait...</span>
								</button>}
						</Div>
					</Div>

					<Spacing lg="100" md="30" />
					<Div className="container cta_bg_image">
						{/* <Cta
						// title="Let’s Create <br />something <i>cool</i> Something Remarkable!"
							title="Ready To Make an Impact?"
							btnText="Apply Now"
							btnLink=""
							bgSrc="/images/cta_bg.jpeg"
						/> */}
						<Div className={`cs-cta cs-style1 cs-bg text-center cs-shape_wrap_1 cs-position_1`} style={{ backgroundImage: `url('/images/career_cta_bg2.jpg')` }}>
							<Div className="cs-shape_1" />
							<Div className="cs-shape_1" />
							<Div className="cs-shape_1" />
							<Div className="cs-cta_in">
								<h2 className="cs-cta_title cs-semi_bold cs-m0">Ready To Make an Impact?</h2>
								<>
									<Spacing lg='70' md='30' />
									{/* <Button
										btnLink={btnLink}
										btnText={btnText}
										/> */}
									<Button onClick={scrollToContactPage} className="theme_btn_new apply_nowBtn" style={{backgroundColor:'#000000'}}>
										Apply Now
									</Button>
								</>

							</Div>
						</Div>
					</Div>

				</Div>
			</Div>
			<Spacing lg="50" md="00" />





			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
				theme="dark"
			/>
		</Box>
	);
}