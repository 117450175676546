import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import "slick-carousel/slick/slick.css";
import './scss/index.scss';
import './css/common.css';
import { ThemeProvider, createTheme } from '@mui/material';
import { Provider } from "react-redux";
import Store from "./redux/store"
import { useSelector } from 'react-redux';
import helperFunction from './helper/simplefun'
import swDev from './swDev';
const ToggleColorMode = () => {
	const colordata = useSelector((state) => state && state.colordata)
	const [mode, setMode] = useState(() => localStorage.getItem('mode') || 'light');
		const theme = createTheme({
		palette: {
			mode: colordata&&colordata?colordata : "dark",
		},
	});
	return (		
		<ThemeProvider theme={theme}>
			<App />
		</ThemeProvider>
	);
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
	<Provider store={Store}>
		<BrowserRouter  >
			<ToggleColorMode />
		</BrowserRouter>
	</Provider >
);

swDev();


