import { Typography } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useNavigate } from "react-router-dom";

export default function MenuWidget({ menuItems, menuHeading, variant }) {
	const navigate = useNavigate();
  const [mobileToggle, setMobileToggle] = useState(false);
  const [category, setCategory] = useState([])
  useEffect(()=>{
    getCategory()
  },[])
  const getCategory = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `category/getAllCategoryPublic`).then((res) => {  
      setCategory(res.data.getallcategories)
    }).catch((error) => {
      console.log(error, "error");
    });
  };


  const handleClickMenu = (e, id) => {    
    e.preventDefault()
    setMobileToggle(false)
    navigate('/service', { state: { categoryId: id, subcategoryId: 'all' } });
  }
  


  return (
    <>
      {menuHeading && <h2 className="cs-widget_title">{menuHeading}</h2>}
      <ul className={`${variant ? `cs-menu_widget ${variant}` : 'cs-menu_widget cs-style1'} cs-mp0`}>
        {category && category.map((item, index) => {

        
           return(

           
          <li key={index}>
            <Typography>{item.category_name && item.category_name.includes("D2G") ? "Digital to Government (D2G)" : item.category_name.includes("D2B") ? 'Digital to Business (D2B)' : item.category_name.includes("D2C") ? 'Digital to Consumer (D2C)' : item.category_name}</Typography> 

          </li>
        )})} 
        {/* <li ><Link onClick={(e) => handleClickMenu(e, '65fbf86504447d56b76c8760')}>Digital-to-Consumer (D2C)</Link></li>
        <li> <Link onClick={(e) => handleClickMenu(e, '65fd31c98c605a2258e6ff86')}>Digital-to-Business (D2B)</Link></li>
        <li><Link onClick={(e) => handleClickMenu(e, '65fd1880cccc3e3ff42f67b0')}>Digital-to-Government (D2G)</Link></li> */}
      </ul>
    </>
  )
}
