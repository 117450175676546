import React, { useEffect } from 'react'
import Portfolio4 from '../Portfolio/portfolio4'

import axios from 'axios';
import Slider from 'react-slick';
import Div from '../Div';
import { Icon } from '@iconify/react';

export default function PortfolioSliderPrject() {
  useEffect(() => {
    getProjects()
  }, [])

  const [project, setProject] = React.useState([])
   const getProjects = async () => {
    await axios.get(process.env.REACT_APP_BASE_URL + `projects/getPrimaryProjectsPublic`).then((res) => {     
      setProject(res.data.getPrimay)
    }).catch((error) => {
      console.log(error, "error");
    });
  };

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-prev slick-arrow' + (currentSlide === 0 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
    >
      <Icon icon="bi:arrow-left" />
    </div>
  );
  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <div
      {...props}
      className={
        'slick-next slick-arrow' +
        (currentSlide === slideCount - 1 ? ' slick-disabled' : '')
      }
      aria-hidden="true"
      aria-disabled={currentSlide === slideCount - 1 ? true : false}
    >
      <Icon icon="bi:arrow-right" />
    </div>
  );

  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: project && project.length > 3 ? 3 : project.length,
    speed: 500,
    dots: false,
    arrows:true,
    prevArrow: <SlickArrowLeft />,
    nextArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: project && project.length > 1 ? 1 : project.length
        }
      }
    ]
  };

  return (
    <>{project && project.length > 0 ? 
      <>
    <Slider {...settings} className={project && project.length <= 3 ? 'portfolio_project_grid home_portfolio_slider' : 'cs-slider cs-style3 cs-gap-24 home_portfolio_slider'} >
      {project && project.map((item, index) => (
        <Div key={index}>
          <Portfolio4
            title={item.service_name}
            subtitle={'See Details'}
            href={'/ourwork/work-details'}
            src={item.additionalimages[0]}
            variant="cs-style1 cs-type1"
            serviceIndex={index}
            service={project && project}
          />
        </Div>
      ))}
    </Slider>
    {/* <Swiper className={project && project.length <= 3 ? 'portfolio_project_grid ' : 'cs-slider cs-style3 cs-gap-24 '}
			modules={[Pagination]}
			slidesPerView={3}
      spaceBetween={10}
			pagination={{
				clickable: true,
			}}
			breakpoints={{
				340: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				500: {
					slidesPerView: 3,
					spaceBetween: 0,
				}
			}}
      
		>

      {project && project.map((item, index) => (
				<SwiperSlide key={index}  >
					<Portfolio4
            title={item.service_name}
            subtitle={'See Details'}
            href={'/project/project-details'}
            src={item.image}
            variant="cs-style1 cs-type1"
            serviceIndex={index}
            service={project && project}
          />
				</SwiperSlide>
			))}

		</Swiper> */}
    </>
    :<h2 className="cs-cta_title cs-semi_bold cs-m0 text-center no_data">No Projects Found</h2>}
    </>
  )
}
