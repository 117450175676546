import React from 'react'
import { Icon } from '@iconify/react';
import './team.scss'
import { Link } from 'react-router-dom';
import Div from '../Div';

export default function Team({memberImage, memberName, memberDesignation, memberLinkedIn, memberFacebook, memberInstagram, memberTwitter}) {

  return (
    <Div className="cs-team cs-style1">
        <Div className="cs-member_thumb">
          <img src={process.env.REACT_APP_BASE_URL + memberImage} alt={memberName} />
          {/* <img src={memberImage} alt={memberName} /> */}
          <Div className="cs-member_overlay" />
        </Div>
        <Div className="cs-member_info">
          <h2 className="cs-member_name"><Link to="/team/team-details">{memberName}</Link></h2>
          <Div className="cs-member_designation">{memberDesignation}</Div>
        </Div>
        <Div className="cs-member_social cs-primary_color">
          {memberLinkedIn && (
            <a href={memberLinkedIn && memberLinkedIn} target="_blank">
              <Icon icon="fa6-brands:linkedin-in" />                   
            </a>
          )}
          {memberTwitter && (      
               <a href={memberTwitter && memberTwitter} target="_blank">
              <Icon icon="fa-brands:twitter" />                                        
              </a>
          )}
          {memberInstagram && (            
               <a href={memberInstagram && memberInstagram} target="_blank">
              <Icon icon="grommet-icons:instagram" />                     
              </a>
          )}
          {memberFacebook && (            
               <a href={memberFacebook && memberFacebook} target="_blank">
              <Icon icon="fa-brands:facebook-f" />                    
              </a>
          )}
        </Div>
      </Div>
  )
}
